import getBeerLink from './getBeerLink'

const getBeerTypes = (beerCategories) => {
  return beerCategories.map((category) => category.slug)
}

export const normalizeBeer = (beer) => {
  const background =
    beer.acf.etiquette &&
    beer.acf.etiquette.background_png &&
    beer.acf.etiquette.background_png.localFile
      ? beer.acf.etiquette.background_png.localFile.childImageSharp.fluid
      : null
  const top =
    beer.acf.etiquette &&
    beer.acf.etiquette.top_png &&
    beer.acf.etiquette.top_png.localFile
      ? beer.acf.etiquette.top_png.localFile.childImageSharp.fluid
      : null
  const bottom =
    beer.acf.etiquette &&
    beer.acf.etiquette.bottom_png &&
    beer.acf.etiquette.bottom_png.localFile
      ? beer.acf.etiquette.bottom_png.localFile.childImageSharp.fluid
      : null

  const beerBottle =
    beer.acf.beer_bottle && beer.acf.beer_bottle.localFile
      ? beer.acf.beer_bottle.localFile.childImageSharp.fixed
      : null

  const beerTranslation = {
    slug: getBeerLink({
      currentLang: beer.polylang_current_lang,
      slug: beer.slug,
    }),
    polylang_translations: beer.polylang_translations,
  }

  return {
    key: beer.slug,
    name: beer.acf.nazev_piva || beer.title || 'Pivo',
    link: getBeerLink({
      currentLang: beer.polylang_current_lang,
      slug: beer.slug,
    }),
    types: beer.beer_cat ? getBeerTypes(beer.beer_cat) : [],
    description: beer.acf.popis_piva || '',
    translations: beerTranslation || [],
    etiquette: {
      background,
      top,
      bottom,
    },
    beerBottle,
    info: {
      foodPairing: beer.acf.kombinace_s_jidlem || '',
      temperature: beer.acf.idealni_teplota || '',
      awards: beer.acf.text_oceneni
        ? beer.acf.text_oceneni.replace(/<br \/>/gi, '').split('\n')
        : '',
      alcohol: beer.acf.obsah_alkoholu || '',
      extract: beer.acf.extrakt || '',
      malt: beer.acf.slad ? beer.acf.slad.replace(/<br \/>/gi, ', ') : '',
      bitterness: beer.acf.horkost || '',
      hop: beer.acf.chmel ? beer.acf.chmel.replace(/<br \/>/gi, ', ') : '',
      color: beer.acf.barva || '',
    },
  }
}

const normalizeBeers = (beers) => {
  if (Array.isArray(beers)) {
    const normalizedBeers = []

    beers.forEach((beerNode) => {
      const beer = beerNode.node

      const newBeer = normalizeBeer(beer)

      normalizedBeers.push(newBeer)
    })

    return normalizedBeers
  }
}

export default normalizeBeers
