import { flatten } from 'lodash'

const flattenEvents = (events) => {
  if (typeof events !== 'undefined' && typeof events.edges !== 'undefined') {
    return flatten(events.edges).map((node) => node.node)
  }
  return events
}

export default flattenEvents
