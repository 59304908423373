import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link, graphql } from 'gatsby'
import {
  Box,
  Flex,
  Heading,
  Link as RebassLink,
} from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import SectionHeader from '../components/section-header'
import Container from '../components/container'
import BeerCarousel from '../components/beer-carousel'
import EventsList from '../components/events-list'
import NextStep from '../components/next-step'
import getLocale from '../utils/getLocale'
import getTranslations from '../utils/getTranslations'
import flattenEvents from '../utils/flattenEvents'
import normalizeBeers from '../utils/normalizeBeers'
import IconCalendar from '../images/inline/icon-calendar.svg'

const IndexPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, beersInCarousel, nextStepBackground, events, eventsListLink } =
    data
  const { title, acf, polylang_translations: translations } = page
  const beers = normalizeBeers(
    beersInCarousel.edges.filter(
      (edge) => edge.node.polylang_current_lang === locale,
    ),
  )
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
  } = acf

  console.log(events)

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <BeerCarousel beers={beers} />

      {events && events.edges && (
        <SectionWithObjects>
          <Container>
            <SectionHeader>
              <Box>
                <Heading variant="sectionTitle" as="h2">
                  <FormattedMessage
                    id="events.title"
                    defaultMessage="Kalendář akcí"
                  />
                </Heading>
              </Box>

              <Flex
                alignItems="flex-start"
                justifyContent={['center', 'flex-end']}
              >
                <Link to={`/${getLocale(locale)}/${eventsListLink.slug}`}>
                  <RebassLink as="span" variant="outlineLight">
                    <FormattedMessage
                      id="events.showAll"
                      defaultMessage="Zobrazit všechny akce"
                    />
                    <IconCalendar />
                  </RebassLink>
                </Link>
              </Flex>
            </SectionHeader>

            <EventsList events={events && flattenEvents(events)} />
          </Container>
        </SectionWithObjects>
      )}

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexTemplateQuery($key: String!, $locale: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
      }
    }
    beersInCarousel: allWordpressWpBeerType(
      sort: { order: ASC, fields: menu_order }
      filter: { acf: { zobrazit_ve_slideshow_na_hlavni_strane: { ne: null } } }
    ) {
      edges {
        node {
          polylang_current_lang
          title
          slug
          beer_cat {
            slug
          }
          acf {
            nazev_piva
            popis_piva
            etiquette {
              background_png {
                ...EtiquettePNGBackground
              }
              top_png {
                ...EtiquettePNGTop
              }
              bottom_png {
                ...EtiquettePNGBottom
              }
            }
          }
        }
      }
    }
    eventsListLink: wordpressPage(
      template: { eq: "page-udalosti.php" }
      polylang_current_lang: { eq: $locale }
    ) {
      slug
    }
    #events: allWordpressWpEvent(sort: { fields: menu_order, order: ASC }) {
    events: allWordpressWpEvent {
      edges {
        node {
          title
          acf {
            od
            do
            lokace
            odkaz
          }
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "sklep-stav-2017.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
