import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import {
  Box,
  Flex,
  Heading,
  Link as RebassLink,
  Text,
} from 'rebass/styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import Section from './section'
import Container from './container'
import TiltLabel from './tilt-label'
import CarouselArrow from '../images/carousel-arrow.png'

const StyledButtonsCSS = css`
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: calc(50% - 1.5rem);
  background: none;
  border: none;
  outline: none;
  background: transparent url(${CarouselArrow}) center center no-repeat;
  background-size: 100%;
  transition: all 100ms ease-in-out;
  text-indent: -10000px;
`

const StyledButtonBack = styled(ButtonBack)`
  ${StyledButtonsCSS}
  left: 1rem;

  &:hover {
    left: 0.5rem;
  }
`

const StyledButtonNext = styled(ButtonNext)`
  ${StyledButtonsCSS}
  right: 1rem;
  transform: rotate(180deg);

  &:hover {
    right: 0.5rem;
  }
`

const SlideContent = ({ link, name, text, html, etiquette, ...others }) => {
  return (
    <Slide {...others}>
      <Container px={[5, 3, 0]}>
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['center', 'flex-start']}
          justifyContent={['flex-start', 'space-between']}
        >
          <Box sx={{ mb: [3, 0], pt: 4, flexBasis: ['100%', '55%'] }}>
            <Heading variant="carouselTitle" as="h2">
              {name}
            </Heading>

            {text && <Text variant="slideText">{text}</Text>}

            {html && (
              <Text
                sx={{
                  whiteSpace: 'break-spaces',
                }}
                dangerouslySetInnerHTML={{ __html: html }}
                variant="slideText"
              />
            )}

            <Link to={link}>
              <RebassLink
                as="span"
                variant="primaryLarge"
                sx={{ display: 'inline-block' }}
              >
                <FormattedMessage
                  id="carousel.moreInfo"
                  defaultMessage="Informace o pivě"
                />
              </RebassLink>
            </Link>
          </Box>

          {etiquette && (
            <Box sx={{ flexBasis: ['100%', '40%'] }}>
              <TiltLabel
                background={etiquette.background}
                top={etiquette.top}
                bottom={etiquette.bottom}
                size="22rem"
              />
            </Box>
          )}
        </Flex>
      </Container>
    </Slide>
  )
}

const BeerCarousel = ({ beers }) => {
  const data = useStaticQuery(graphql`
    query {
      pivoBar: file(relativePath: { eq: "pivo-cepovani.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { pivoBar: backgroundImage } = data

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: ['auto'],
      }}
    >
      <BackgroundImage
        fadeIn={false}
        loading="eager"
        fluid={backgroundImage.childImageSharp.fluid}
        style={{
          backgroundPosition: '50% 10%',
          backgroundColor: '#333',
          backgroundSize: 'cover',
        }}
      >
        <Section
          pt={[2, 4, 5]}
          pb={[2, 4, 5]}
          sx={{
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(49, 40, 2, 0) 0%, rgba(49, 40, 2, 0.75) 100%)',
            zIndex: 1000,
          }}
        >
          <CarouselProvider
            naturalSlideWidth={1280}
            naturalSlideHeight={800}
            totalSlides={beers.length}
            infinite
            isPlaying
            isIntrinsicHeight
          >
            <Slider>
              {beers.map((beer, i) => (
                <SlideContent
                  key={beer.link}
                  index={i}
                  link={beer.link}
                  name={beer.name}
                  html={beer.description || null}
                  etiquette={beer.etiquette}
                />
              ))}
            </Slider>
            <StyledButtonBack>
              <FormattedMessage
                id="carousel.previous"
                defaultMessage="Předchozí"
              />
            </StyledButtonBack>

            <StyledButtonNext>
              <FormattedMessage id="carousel.next" defaultMessage="Další" />
            </StyledButtonNext>
          </CarouselProvider>
        </Section>
      </BackgroundImage>
    </Box>
  )
}

BeerCarousel.propTypes = {
  beers: PropTypes.array.isRequired,
}

SlideContent.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  html: PropTypes.string,
  etiquette: PropTypes.object,
}

SlideContent.defaultProps = {
  text: null,
  html: null,
  etiquette: null,
}

export default BeerCarousel
