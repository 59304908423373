import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate, FormattedMessage } from 'react-intl'
import {
  Box,
  Flex,
  Heading,
  Text,
  Link,
} from 'rebass/styled-components'

const DateElement = ({ dateFrom, dateTo }) => {
  return (
    <Box
      sx={{
        minWidth: ['8rem', '6rem', '10rem'],
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '12px',
          borderRadius: '4px 4px 0 0',
          backgroundColor: 'red',
        }}
      />

      <Box
        sx={{
          py: [2, 2, 3],
          px: 2,
          bg: 'white',
          color: 'darkGray',
          textAlign: 'center',
        }}
      >
        <Text as="p" sx={{ fontSize: [6, 6, 8], fontWeight: 'bold' }}>
          {!dateTo && (
            <FormattedDate
              value={Date.parse(dateFrom)}
              month="numeric"
              day="numeric"
            />
          )}
          {dateTo && (
            <>
              <FormattedDate
                value={Date.parse(dateFrom)}
                month="numeric"
                day="numeric"
              />
              —
              <FormattedDate
                value={Date.parse(dateTo)}
                month="numeric"
                day="numeric"
              />
            </>
          )}
        </Text>
      </Box>
    </Box>
  )
}

const Title = (props) => <Heading as="h3" variant="eventTitle" sx={{ minHeight: '3.5rem' }} {...props} />

const Location = (props) => <Text as="p" variant="eventLocation" {...props} />

const Event = ({
  dateFrom, dateTo, title, location, link,
}) => {
  return (
    <Flex>
      <Box mr={3} flexGrow={0}>
        <DateElement dateFrom={dateFrom} dateTo={dateTo} />
      </Box>

      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        flexBasis={['auto', 'auto', '65%']}
        flexShrink="300"
      >
        <Title>{title}</Title>

        <Location>{location || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}</Location>

        {link ? (
          <Link href={link} target="_blank" rel="noopener noreferrer">
            <FormattedMessage
              id="events.moreInfo"
              defaultMessage="Více informací"
            />
          </Link>
        ) : (
          <Text color="white">
            <FormattedMessage
              id="events.moreInfoOnSocial"
              defaultMessage="Více informací na sociálních sítích"
            />
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

const EventsList = ({ events }) => {
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {events
        && events.map((event) => (
          <Box key={event.title} sx={{ width: ['100%', '100%', '50%'], mb: 4 }}>
            <Event
              dateFrom={event.acf.od}
              dateTo={event.acf.do || null}
              title={event.title}
              location={event.acf.lokace || null}
              link={event.acf.odkaz || null}
            />
          </Box>
        ))}
    </Flex>
  )
}

Event.propTypes = {
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string,
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  link: PropTypes.string,
}

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
}

DateElement.propTypes = {
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string,
}

export default EventsList
